import { useEffect, useState } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { TimerStatusEnum } from '../../../_common/enum';
import { IDropdownData } from '../../../_common/interface';
import { IUserZoneSelect } from '../../../_common/models/userZone/userZoneSelect.model';
import { PrimaryButton } from '../../../_components/buttons/primary.button';
import { toastr } from '../../../_components/toastr/toastr';
import { handleErrorResponse } from '../../../_helpers';
import { dropdownService } from '../../../_services/dropdown.service';
import { userZoneService } from '../../../_services/userZone.service';

import { Collapse, FormControl, Grid, InputLabel, MenuItem, Paper, Select } from '@material-ui/core';
import { Html5Qrcode } from "html5-qrcode";

export function WorkerZoneSelect(params: {
    userZoneSelect: IUserZoneSelect,
    emitOnZoneChangeToggle: (changeZone: boolean) => void,
    emitOnSaveZoneSelected: () => void,
}) {

    const [qrCodeReader, setQrCodeReader] = useState<Html5Qrcode>();

    const [clients, setClients] = useState<Array<IDropdownData>>([]);
    const [buildings, setBuildings] = useState<Array<IDropdownData>>([]);
    const [zones, setZones] = useState<Array<IDropdownData>>([]);

    const [userZoneSelect, setUserZoneSelect] = useState<IUserZoneSelect>(params.userZoneSelect);
    const [isLoading, setLoading] = useState(true);

    const [changeZone, setChangeZone] = useState(false);

    const [scanZone, setScanZone] = useState(false);
    const [cameraId, setCameraId] = useState<string | MediaTrackConstraints>("");

    useEffect(() => {
        fetchClients();
    }, []);

    const isAlarmGoingOff = !!(params.userZoneSelect.timerStatus & TimerStatusEnum.Alert);
    useEffect(() => {
        if (isAlarmGoingOff) {
            setChangeZone(false);
        }
    }, [params.userZoneSelect.timerStatus]);

    useEffect(() => {
        setUserZoneSelect(params.userZoneSelect);
    }, [params.userZoneSelect.zoneId]);

    useEffect(() => {
        params.emitOnZoneChangeToggle(changeZone);
    }, [changeZone]);

    useEffect(() => {
        if (!userZoneSelect.clientId) setBuildings([]);
        else fetchBuildings();
    }, [userZoneSelect.clientId]);

    useEffect(() => {
        if (buildings && buildings.length === 1 && buildings[0].id !== userZoneSelect.buildingId) {
            setUserZoneSelect(_zone => {
                var __zone = { ..._zone };
                __zone.buildingId = buildings[0].id;
                return __zone;
            })
        }
    }, [buildings]);

    useEffect(() => {
        if (zones && zones.length === 1 && zones[0].id !== userZoneSelect.zoneId) {
            setUserZoneSelect(_zone => {
                var __zone = { ..._zone };
                __zone.zoneId = zones[0].id;
                return __zone;
            })
        }
    }, [zones]);

    useEffect(() => {
        if (!userZoneSelect.buildingId) setZones([]);
        else fetchZones();
    }, [userZoneSelect.buildingId]);

    function fetchClients() {
        dropdownService.getClientsForUser()
            .then(
                (json) => setClients(json),
                handleErrorResponse
            )
            .finally(() => setLoading(false));
    }

    function fetchBuildings() {
        if (!userZoneSelect.clientId) return;

        setLoading(true);
        dropdownService.getBuildingsForClient(+(userZoneSelect.clientId || 0))
            .then(
                (json) => {
                    setBuildings(json);
                }
                , handleErrorResponse
            )
            .finally(() => setLoading(false));
    }

    function fetchZones() {
        if (!userZoneSelect.buildingId) return;

        setLoading(true);
        dropdownService.getZonesForBuilding(+(userZoneSelect.buildingId || 0))
            .then(
                (json) => setZones(json), handleErrorResponse
            )
            .finally(() => setLoading(false));
    }

    function submit() {
        setLoading(true);

        return userZoneService
            .createUserZoneRecord(+(userZoneSelect.zoneId || 0))
            .then(
                (json: any) => {
                    params.emitOnSaveZoneSelected();
                }, (e: any) => { toastr.error("Error setting zone"); }
            )
            .finally(() => {
                setLoading(false);
                setChangeZone(false);
            })

    }

    function submitScan(zoneId: number) {
        return userZoneService
            .createUserZoneRecord(zoneId)
            .then(
                (json: any) => {
                    stopScanning();
                    params.emitOnSaveZoneSelected();
                }, (e: any) => {
                    qrCodeReader?.resume();
                    toastr.error("Invalid zone");
                }
            );

    }

    function handleClientChange(e: any) {
        setUserZoneSelect(_zoneSelect => {
            return {
                ..._zoneSelect,
                clientId: +(e.target.value || 0),
                buildingId: 0,
                zoneId: 0
            }
        });
    }

    function handleBuildingChange(e: any) {
        setUserZoneSelect(_zoneSelect => {
            return {
                ..._zoneSelect,
                buildingId: +(e.target.value || 0),
                zoneId: 0
            }
        });
    }

    function handleZoneChange(e: any) {
        setUserZoneSelect(_zoneSelect => {
            return {
                ..._zoneSelect,
                zoneId: +(e.target.value || 0)
            }
        });
    }

    function onScanSuccess(decodedText: any, decodedResult: any) {
        // handle the scanned code as you like, for example:
        console.log(`Code matched = ${decodedText}`, decodedResult);
    }

    function onScanFailure(error: any) {
        // handle scan failure, usually better to ignore and keep scanning.
        // for example:
        console.log(`STOP`);
    }


    useEffect(() => {

        // let html5QrcodeScanner = new Html5QrcodeScanner(
        //     "reader",
        //     { fps: 10, qrbox: { width: 250, height: 250 } },

        // /* verbose= */ false);

        // html5QrcodeScanner.render(onScanSuccess, onScanFailure);


        // Html5Qrcode.getCameras().then(devices => {
        //     /**
        //      * devices would be an array of objects of type:
        //      * { id: "id", label: "label" }
        //      */
        //     if (devices && devices.length) {
        //         var cameraId = devices[0].id;
        //         // .. use this to start scanning.
        //     }
        // }).catch(err => {
        //     // handle err
        // });

        // // const html5QrCode = new Html5Qrcode(/* element id */ "reader");
        // // html5QrCode.start(
        // //     cameraId,
        // //     {
        // //         fps: 10,    // Optional, frame per seconds for qr code scanning
        // //         qrbox: { width: 250, height: 250 }  // Optional, if you want bounded box UI
        // //     },
        // //     (decodedText, decodedResult) => {
        // //         // do something when code is read
        // //     },
        // //     (errorMessage) => {
        // //         // parse error, ignore it.
        // //     })
        // //     .catch((err) => {
        // //         // Start failed, handle it.
        // //     });

    }, []);

    // useEffect(() => {
    //     if (Html5QrcodeScanner) {
    //         // Creates anew instance of `HtmlQrcodeScanner` and renders the block.
    //         let html5QrcodeScanner = new Html5QrcodeScanner(
    //             "reader",
    //             { fps: 10, qrbox: { width: 250, height: 250 } },
    //         /* verbose= */ false);

    //         html5QrcodeScanner.render(
    //             (data: any) => console.log('success ->', data),
    //             (err: any) => console.log('err ->', err)
    //         );
    //     }
    // }, [Html5QrcodeScanner]);

    useEffect(() => {

        Html5Qrcode.getCameras().then(devices => {
            /**
             * devices would be an array of objects of type:
             * { id: "id", label: "label" }
             */
            if (devices && devices.length) {
                console.log('camera devices: ', devices);
                setCameraId(devices[0].id);
                // .. use this to start scanning.
            }
        }).catch(err => {
            // handle err
        });

        let _qrCodeReader = new Html5Qrcode(/* element id */ "reader");
        setQrCodeReader(_qrCodeReader);
    }, []);

    const isCameraPossible = cameraId && qrCodeReader;
    const isCameraEnabled = scanZone && isCameraPossible;

    useEffect(() => {
        if (isCameraEnabled) {
            (qrCodeReader as Html5Qrcode).start(
                { facingMode: "environment" },
                {
                    fps: 10,    // Optional, frame per seconds for qr code scanning
                    qrbox: { width: 250, height: 250 }  // Optional, if you want bounded box UI
                },
                (decodedText: any, decodedResult: any) => {
                    console.log(decodedText, decodedResult);
                    console.log(decodedText.indexOf(";"), decodedText.split(";").length);
                    if (decodedText.indexOf(";") > -1 && decodedText.split(";").length === 3) {
                        qrCodeReader.pause();
                        submitScan(((decodedText.split(";")[2] || 0) as number));
                    }
                    else {
                        toastr.error("Invalid QR code");
                    }
                },
                (errorMessage: any) => {
                    // parse error, ignore it.
                })
                .catch((err): any => {
                    // Start failed, handle it.
                });
        }
    }, [scanZone]);

    function stopScanning() {
        (qrCodeReader as Html5Qrcode).stop().then((ignore: any) => {
            // QR Code scanning is stopped.
            setScanZone(false)
        }).catch((err) => {
            // Stop failed, handle it.
        });
    }

    return (
        <Paper>

            <Collapse in={!changeZone && !scanZone}>

                {(userZoneSelect.zoneId > 0 && zones && zones.length > 0) &&
                    <p style={{ margin: "0px" }}>Current Zone: <strong>{zones.find(m => m.id === userZoneSelect.zoneId)?.name}</strong>  </p>
                }

                {(!userZoneSelect.zoneId) &&
                    <p style={{ margin: "0px" }}>Select a Zone </p>
                }

                <Grid container>
                    <Grid item xs={isCameraPossible ? 8 : 12}>
                        <PrimaryButton
                            onClick={() => setChangeZone(true)}
                            variant="success">
                            {changeZone ? 'Confirm Change Zone' : 'Change Zone Manually'}
                        </PrimaryButton>
                    </Grid>

                    {isCameraPossible &&
                        <Grid item xs={4}>
                            <PrimaryButton
                                onClick={() => setScanZone(true)}
                                variant="primary">
                                Scan Zone
                            </PrimaryButton>
                        </Grid>
                    }
                </Grid>
            </Collapse>

            <Collapse in={scanZone}>
                <h2> Scan A Zone</h2>

                <div id="reader" style={{ width: "100%" }}></div>

                <PrimaryButton
                    variant="remove"
                    onClick={() => { stopScanning() }}>
                    Cancel
                </PrimaryButton>
            </Collapse>

            <Collapse in={changeZone}>

                <ValidatorForm onSubmit={submit} noValidate>

                    <h2> Select A Zone</h2>

                    <Grid container>
                        <Grid item xs={12}>

                            <FormControl fullWidth>
                                <InputLabel id="demo-select-small">Client</InputLabel>
                                <Select
                                    fullWidth
                                    name="clientId"
                                    variant="filled"
                                    label="Please Select Client"
                                    required

                                    onChange={handleClientChange}
                                    value={!clients.find(m => m.id === userZoneSelect.clientId) ? "" : userZoneSelect.clientId}

                                    disabled={(!clients || !clients.length || isLoading)}
                                >
                                    {(clients && clients.length > 0) && clients.map((item) => <MenuItem key={`client_${item.id}`} value={item.id}>{item.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-select-small">Building</InputLabel>
                                <Select
                                    value={!buildings.find(m => m.id === userZoneSelect.buildingId) ? "" : userZoneSelect.buildingId}
                                    onChange={handleBuildingChange}
                                    required
                                    label="Please Select Building"
                                    name="buildingId"
                                    fullWidth
                                    defaultValue={""}
                                    disabled={(!buildings || !buildings.length || isLoading || !userZoneSelect.clientId)}
                                    variant="filled">
                                    {(buildings && buildings.length > 0) && buildings.map((item) => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-select-small">Zone</InputLabel>
                                <Select
                                    value={!zones.find(m => m.id === userZoneSelect.zoneId) ? "" : userZoneSelect.zoneId}
                                    onChange={handleZoneChange}
                                    required
                                    label="Please Select Zone"
                                    name="zoneId"
                                    fullWidth
                                    defaultValue={""}
                                    disabled={(!zones || !zones.length || isLoading || !userZoneSelect.buildingId)}
                                    variant="filled">
                                    {(zones && zones.length > 0) && zones.map((item) => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={8}>
                            <PrimaryButton
                                variant="save"
                                type="submit"
                                disabled={isLoading || !userZoneSelect.zoneId}>
                                Confirm
                            </PrimaryButton>
                        </Grid>

                        <Grid item xs={4}>
                            <PrimaryButton
                                variant="remove"
                                onClick={() => { setUserZoneSelect(params.userZoneSelect); setChangeZone(false) }}>
                                Cancel
                            </PrimaryButton>
                        </Grid>
                    </Grid>

                    {/* {
                        (!isLoading && zoneId) &&
                        <>

                            <Grid container justifyContent="center">
                                <Grid>
                                    <h2>You Are Now Starting Work</h2>
                                </Grid>
                            </Grid>

                            <Grid container justifyContent="center">
                                <Grid>
                                    <h3><strong>Date:</strong> {nowDateStr}</h3>
                                </Grid>

                            </Grid>
                        </>
                    } */}

                </ValidatorForm >
            </Collapse>

        </Paper >
    );
};
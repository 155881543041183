import { Route, Router, Switch } from 'react-router-dom';
import './App.css';
import { AppScreen } from './app/app-screen';
import { AuthScreen } from './auth/auth-screen';
import { THEME } from './_common/const';
import { historyHelper } from './_helpers';
import { ThemeProvider } from '@material-ui/styles';
import React from 'react';



export function App() {

	return (
		<>
			<ThemeProvider theme={THEME}>

				<Router history={historyHelper}>
					<Route path='/'>

						<Switch>
							<Route path='/login' component={AuthScreen} />
							<Route path='/' component={AppScreen} />
						</Switch>

					</Route>

				</Router>
			</ThemeProvider >
		</>

	);
}

export default App;

import React from 'react';
import { VERSION } from '../../_common/const/app.const';
import { Link, Typography } from '@material-ui/core';


export function AuthFooter() {
    return (
        <Typography variant="body2" color="inherit" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://lyonsgroup.ie/">
                Lyons Group
            </Link>{' '}
            {new Date().getFullYear()} v{VERSION}
        </Typography>
    );
}
export enum TimerStatusEnum {
    Paused          = 1,
    Main            = 2,
    Grace           = 4,
    Alert           = 8,
    NotAlone        = 16,
    IsStoped        = Paused | Alert,
    IsRunning       = Main | Grace,
    ShouldAlertPlay = Alert | Grace
}

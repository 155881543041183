
import { makeStyles } from "@material-ui/core/styles";
import { PRIMARY_COLOR, PURPLE_COLOR, SUCCESS_COLOR, DANGER_COLOR, INFO_COLOR, WARNING_COLOR } from '../_common/const';

export const useButtonStyles = makeStyles(theme => ({
    button: {
        borderRadius: "0px",
        color: "white",
        textTransform: "uppercase",
        width: "100%",
        marginLeft: "0px !important",
        marginBottom: "0px !important",
        marginTop: "5px",
        minHeight: "38px",
        maxHeight: "75px",
        "&:hover": {
            color: "white",
            boxShadow: "none",
            cursor: "pointer"
        },
        "& .material-icons": {
            marginBottom: "0px"
        },
        "&:disabled": {
            backgroundColor: "silver !important",
            color: "black !important",
            cursor: "auto !important"
        }
    },
    compact: {
        paddingLeft: "0px",
        paddingRight: "0px"
    },

    cellButton: {
        marginTop: "0px",
        height: "100%"
    },

    primaryButton: {
        backgroundColor: PRIMARY_COLOR[0],
        "&:hover,&:focus": {
            backgroundColor: PRIMARY_COLOR[1]
        }
    },

    secondaryButton: {
        backgroundColor: PURPLE_COLOR[0],
        "&:hover,&:focus": {
            backgroundColor: PURPLE_COLOR[1]
        }
    },

    activeButton: {
    },

    successsButton: {
        backgroundColor: SUCCESS_COLOR[0],
        "&:hover,&:focus": {
            backgroundColor: SUCCESS_COLOR[1]
        }
    },

    dangerButton: {
        backgroundColor: DANGER_COLOR[0],
        "&:hover,&:focus": {
            backgroundColor: DANGER_COLOR[1]
        }
    },

    infoButton: {
        backgroundColor: INFO_COLOR[0],
        "&:hover,&:focus": {
            backgroundColor: INFO_COLOR[1]
        }
    },

    warningButton: {
        backgroundColor: WARNING_COLOR[0],
        "&:hover,&:focus": {
            backgroundColor: WARNING_COLOR[1]
        }
    },
    defaultButton: {
        backgroundColor: '#e0e0e0',
        color: "black",
        "&:hover,&:focus": {
            color: "black",
            backgroundColor: '#d5d5d5'
        }
    },

    disabledButton: {
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
        color: "rgba(0, 0, 0, 0.26)",
        boxShadow: "none"
    },
    // App specific
    confirmOKButton: {
        backgroundColor: SUCCESS_COLOR[0],

        "&:hover,&:focus": {
            backgroundColor: SUCCESS_COLOR[1]
        },
        "&.dense-true": {
            height: '80px',
            transition: "height 0.3s ease",
            margin: "0px"
        },
        "&.dense-false": {
            height: '150px',
            transition: "height 0.3s ease",
        },
        fontSize: '28px',
        maxHeight: 'none'
    },

    blinkConfirmOKWarningButton: {
        backgroundColor: WARNING_COLOR[0],
        animation: 'flashing 750ms linear infinite',
        "&.dense-true": {
            height: '80px',
            transition: "height 0.3s ease",
            margin: "0px"
        },
        "&.dense-false": {
            height: '150px',
            transition: "height 0.3s ease",
        },
        fontSize: '28px',
        maxHeight: 'none'
    },

    blinkConfirmOKDangerButton: {
        backgroundColor: DANGER_COLOR[0],
        animation: 'flashing 750ms linear infinite',
        "&.dense-true": {
            height: '80px',
            transition: "height 0.3s ease",
            margin: "0px"
        },
        "&.dense-false": {
            height: '150px',
            transition: "height 0.3s ease",
        },
        fontSize: '28px',
        maxHeight: 'none'
    },
    needAssistanceButton: {
        backgroundColor: DANGER_COLOR[0],
        "&:hover,&:focus": {
            backgroundColor: DANGER_COLOR[1]
        },
        "&.dense-true": {
            height: '80px',
            transition: "height 0.3s ease",
            margin: "0px"
        },
        "&.dense-false": {
            height: '150px',
            transition: "height 0.3s ease",
        },
        fontSize: '28px',
        maxHeight: 'none'
    },
    successLarge: {
        backgroundColor: SUCCESS_COLOR[0],
        fontSize: '28px',
        height: '80px',
        maxHeight: 'none'
    },
    dangerLarge: {
        backgroundColor: DANGER_COLOR[0],
        fontSize: '28px',
        height: '80px',
        maxHeight: 'none'
    }

}));
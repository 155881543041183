import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import React, { FormEvent, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { PaperHeader } from "../../_components/paperComponents/header.paperComponent";
import { toastr } from '../../_components/toastr/toastr';
import { historyHelper } from '../../_helpers';
import { accountService } from '../../_services';
import { Button, Link } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: theme.spacing(2),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    header: {
        marginLeft: theme.spacing(-8),
    },
    mainBackground: {
    },

    logo: {
        width: "100%",
        height: "100px",
    },
    backToLogin: {
        paddingTop: "20px",
        cursor: "pointer"
    }
}));

export function LoginForgotPassword() {
    const [email, setEmail] = useState("");
    const [isEmailReset, resetEmail] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const classes = useStyles();

    function submit(e: FormEvent) {
        e.preventDefault();
        setLoading(true);

        accountService.forgotPassword(email)
            .then(
                (item => { resetEmail(true); }),
                ((err) => {
                    toastr.error("Something went wrong. Please try again later");
                }))
            .finally(() => {
                setLoading(false);
            });
    }

    function goTo(link: string) {
        historyHelper.push('/' + link);
    }

    if (accountService.currentUserValue) {
        historyHelper.push('/');
    }

    return (

        <div className={classes.paper}>
            <div className={classes.header}>
                <PaperHeader title='Forgot Password' icon='lock'></PaperHeader>
            </div>

            {isEmailReset === true &&
                <>
                    <Alert severity="success">Please check your email and click on the provided link to reset your password.</Alert>

                </>
            }

            {isEmailReset === false &&
                <>
                    <Alert severity="warning">Please enter your email address to request a password reset.</Alert>

                    <ValidatorForm className={classes.form} onSubmit={submit} noValidate>
                        <TextValidator
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="Email"
                            name="email"
                            value={email}
                            disabled={isLoading}
                            onChange={(e: any) => setEmail(e.target.value.toString())}
                            validators={['required', 'isEmail']}
                            errorMessages={['This field is required', 'Email is not valid']}
                            autoFocus
                        />

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={isLoading}
                            disableElevation
                            className={classes.submit}
                        >
                            Request Password Reset
                        </Button>

                    </ValidatorForm>

                </>
            }

            <Link onClick={() => goTo("login")} className={classes.backToLogin}>
                Back to Log In screen
            </Link>

        </div>
    );
}
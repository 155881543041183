
import { Button, Grid, Link } from "@material-ui/core";
import React, { FormEvent, useState } from "react";
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import InstallPWA from "../../_components/installer/installer.pwa";
import { PaperHeader } from "../../_components/paperComponents/header.paperComponent";
import { toastr } from '../../_components/toastr/toastr';
import { historyHelper } from "../../_helpers";
import { accountService } from "../../_services";
import { userLoginService } from "../../_services/userLogin.service";
import { usetAuthScreenStyles } from "../_styles/authScreen.styles";
import { Alert } from '@material-ui/lab'; 

export function LoginIndex() {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [rememberMe, setRememberMe] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const classes = usetAuthScreenStyles();

    function submit(e: FormEvent) {
        e.preventDefault();
        setLoading(true);
        setErrorMsg("");

        accountService.login(username, password, rememberMe)
            .then(
                (item => {
                    setUserStartTime();
                }),
                ((err) => {
                    if (err === 'Username or password is incorrect'
                        || err === 'Client License Expired'
                        || err === 'Number of Client Employees Exceeds No. of Employees Licensed') {
                        setErrorMsg(err);
                    }

                    else {
                        toastr.error("Something went wrong. Please try again later");
                    }
                }))
            .finally(() => { setLoading(false); });
    }

    function goTo(link: string) {
        historyHelper.push('/' + link);
    }

    if (accountService.currentUserValue) {
        historyHelper.push('/');
    }

    function setUserStartTime() {
        return userLoginService
            .createUserLoginRecord()
            .then(
                (json: any) => { },
                (e: any) => { toastr.error("Error setting user start time"); }
            );
    }

    return (
        <div className={classes.paper}>
            <div className={classes.header}>
                <PaperHeader title='Sign In' icon='lock'></PaperHeader>
            </div>

            {errorMsg &&
                <Alert severity="error">{errorMsg}!</Alert>
            }
            <Grid container>
                <Grid item>
                    <span className={classes.safetySolution}>Your Safety Solution for staff working alone or in a vulnerable scenario</span>
                </Grid>
            </Grid>
            <ValidatorForm className={classes.form} onSubmit={submit} noValidate>
                <Grid container>

                    <Grid item xs={12}>
                        <TextValidator
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="Email"
                            name="email"
                            value={username}
                            disabled={isLoading}
                            onChange={(e: any) => setUsername(e.target.value.toString())}
                            validators={['required', 'isEmail']}
                            errorMessages={['This field is required', 'Email is not valid']}
                            autoFocus
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextValidator
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            disabled={isLoading}
                            value={password}
                            onChange={(e: any) => setPassword(e.target.value.toString())}
                            validators={['required']}
                            errorMessages={['This field is required']}
                            autoComplete="current-password"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={isLoading}
                            disableElevation
                            className={classes.submit}
                        >
                            Sign In
                        </Button>
                    </Grid>
                </Grid>
            </ValidatorForm>

            <Link onClick={() => goTo("login/forgotpassword")} className={classes.link}>
                Forgot Password
            </Link>
            <br />
            <InstallPWA />

            {/* <button type="button" onClick={() => { serviceWorkerRegistration.killExisting();}}>Refresh</button> */}

        </div>
    );
}
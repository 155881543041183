import { makeStyles } from "@material-ui/core/styles";

export const usetAuthScreenStyles = makeStyles(theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: theme.spacing(2),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        "&>div>div": {
            marginTop: "10px"
        }
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    header: {
        marginLeft: theme.spacing(-8),
    },
    appLogo: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    appContainer: {
        paddingTop: theme.spacing(4),
    },
    link: {
        paddingTop: "20px",
        cursor: "pointer"
    },
    safetySolution: {
        color: theme.palette.grey[600],
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: "center",
        marginLeft: theme.spacing(3.5),
        marginRight: theme.spacing(3.5)
    }
}));

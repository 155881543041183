import React, { useEffect, useState } from "react";
import { isMobile } from 'react-device-detect';
import { toastr } from "../toastr/toastr";
import { Alert, AlertTitle } from "@material-ui/lab";

const InstallPWA = () => {
    const [supportsPWA, setSupportsPWA] = useState(false);
    const [promptInstall, setPromptInstall] = useState<any>(null);
    const [promptiOSInstall, setPromptiOSInstall] = useState(false);

    useEffect(() => {
        checkIOSCanInstall();

        const handler = (e: any) => {
            e.preventDefault();
            console.log("we are being triggered :D");
            setSupportsPWA(true);
            setPromptInstall(e);
        };

        window.addEventListener("beforeinstallprompt", handler);

        return () => window.removeEventListener("transitionend", handler);
    }, []);

    const onClick = (evt: any) => {
        evt.preventDefault();

        if (!promptInstall) {
            toastr.error("Unable to install");
            return;
        }

        promptInstall.prompt();
    };

    function checkIOSCanInstall() {
        let ios = false;
        const userAgent = window.navigator.userAgent.toLowerCase();
        ios = /iphone|ipad/.test(userAgent);

        let windowNav: any;
        windowNav = window.navigator; // window.navigator.standalone 
        const isStandalone = () => ('standalone' in window.navigator) && (windowNav.standalone);

        if (ios) {
            if (!isStandalone()) {
                // Not launched from home screen
                setPromptiOSInstall(true);
            }
        }
    }

    // if (!supportsPWA) {
    //     return null;
    // }

    return (
        <>
            {(supportsPWA && !promptiOSInstall) &&
                <button
                    className="link-button"
                    id="installInstructions"
                    aria-label="Install app"
                    title="Install app"
                    onClick={onClick}
                >
                    Install The App
                </button>
            }
            {(promptiOSInstall && !supportsPWA) &&
                <Alert severity="info" color="info">
                    <AlertTitle>Install this app</AlertTitle>
                    Click share and Add to Home Screen
                </Alert>
            }
        </>
    );
};

export default InstallPWA;
import { getBody, getHeader, getQuery, getUploadHeader } from '../_helpers';
import { handleResponse } from '../_helpers/handle-response';
import { API_URL } from '../_common/const';
import { IUserZoneManage } from '../_common/models/userZone/userZoneManage.model';

const API_NAME = "escalationAlert";

export const escalationAlertService = {
    createPanicEscalationAlert: CreatePanicEscalationAlert,
    createTimeoutEscalationAlert: CreateTimeoutEscalationAlert
};

function CreatePanicEscalationAlert() {
    const requestOptions = { method: 'POST', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/CreatePanicEscalationAlert`, requestOptions).then(handleResponse);
}

function CreateTimeoutEscalationAlert() {
    const requestOptions = { method: 'POST', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/CreateTimeoutEscalationAlert`, requestOptions).then(handleResponse);
}
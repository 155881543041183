import { accountService } from '../../_services/account.service';

export interface IPrimaryNavInterface {
    icon: string;
    name: string;
    mobileName?: string;
    url: string;
    onClick?: any;
    png?: string;
}


const WORKER_NAV: IPrimaryNavInterface[] = [
    { icon: 'logout', name: 'Log Out', url: 'logout', onClick: accountService.logout }
];

export { WORKER_NAV };


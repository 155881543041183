import { getBody, getHeader } from '../_helpers';
import { handleResponse } from '../_helpers/handle-response';
import { API_URL } from '../_common/const';
import { ILatLng } from '../_common/models/location/latLng.model';

const API_NAME = "userLocation";

export const userLocationService = {
    createUserLocation,
};

function createUserLocation(model?: ILatLng) {
    const requestOptions = { method: 'POST', headers: getHeader(), body: getBody(model) };
    return fetch(`${API_URL}/${API_NAME}/createUserLocation`, requestOptions).then(handleResponse);
}

import { createTheme } from '@material-ui/core';
import { PRIMARY_COLOR, SECONDARY_COLOR } from '.';

// declare module '@mui/material/styles' {
//     interface BreakpointOverrides {
//       xs: true; // removes the `xs` breakpoint
//       sm: true;
//       md: true;
//       lg: true;
//       xl: true;
//       mobileXS: true;
//       mobileSM: true;
//     }
//   }

export const THEME = createTheme({
    breakpoints: {
        values: {
            xs: 405,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
    palette: {
        primary: {
            main: PRIMARY_COLOR[0],
        },
        secondary: {
            main: SECONDARY_COLOR[0]
        }
    }
});
import { useEffect, useState } from "react";
import { getSecondsRemaining } from "../../../_helpers/time.helper";
import { userLocationService } from "../../../_services/userLocation.service";
import { toastr } from "../../../_components/toastr/toastr";

export function GeoLocationRecorderComponent() {
    const [isLoading, setLoading] = useState(false);
    const [timerOptions, setTimerOptions] = useState({
        seconds: 30,
        lastUpdate: 0
    });

    useEffect(() => {
        let timer = setInterval(tick, 1000);
        return () => { clearInterval(timer) };
    }, []);

    function tick() {

        if (timerOptions.seconds > 0) {
            setTimerOptions(s => {

                let _time = getSecondsRemaining(s.lastUpdate, s.seconds);

                return {
                    ...s,
                    seconds: _time.seconds,
                    lastUpdate: _time.lastUpdate
                }
            });
        }
    }

    useEffect(() => {
        if (timerOptions.seconds <= 0) {
            recordCurrentGeoLocation();
        }
    }, [timerOptions.seconds <= 0]);

    function recordCurrentGeoLocation() {
        if ("geolocation" in navigator) {
            setLoading(true);
            navigator.geolocation.getCurrentPosition(function (position) {
                const loc = {
                    latitude: position.coords.latitude.toString(),
                    longitude: position.coords.longitude.toString()
                };

                userLocationService.createUserLocation(loc)
                    .then(data => { console.log('location recorded', new Date()) },
                        (e) => {
                            toastr.error("Error recording user location");
                        })
                    .finally(() => { setLoading(false); });
            });
        }
    }

    return (<></>);
}
import { useEffect, useState } from "react";
import { TimerStatusEnum } from "../../../_common/enum";
import { ITimerOptions } from "../../../_common/models";
import { formatedTimeStringFromSeconds, getSecondsRemaining } from "../../../_helpers/time.helper";
import { accountService } from "../../../_services";
import { tickLogService } from "../../../_services/tickLog.service";

export function WorkerZoneTimer(params: {
    timerOptions: ITimerOptions,
    emitOnTimerExpire: () => void
}) {

    const [timerOptions, setTimerOptions] = useState({
        seconds: 0,
        resetCounter: 0,
        timerStatus: TimerStatusEnum.Paused,
        lastUpdate: 0
    });

    useEffect(() => {

        // console.log("pre reset", params.timerOptions.resetCounter, timerOptions.resetCounter, params.timerOptions);
        if (params.timerOptions.resetCounter === timerOptions.resetCounter && params.timerOptions.timerStatus === timerOptions.timerStatus) return;
        // console.log("post reset", params.timerOptions.resetCounter, timerOptions.resetCounter, params.timerOptions);

        setTimerOptions(options => {
            return {
                timerStatus: params.timerOptions.timerStatus,
                seconds: params.timerOptions.initialSecondsCountdown > 0 ? params.timerOptions.initialSecondsCountdown : 0,
                resetCounter: params.timerOptions.resetCounter,
                lastUpdate: 0
            }
        });

    }, [params.timerOptions.timerStatus, params.timerOptions.resetCounter]);

    useEffect(() => {
        if (timerOptions.seconds <= 0 || (timerOptions.timerStatus & TimerStatusEnum.IsStoped)) return;
        let _trigger = tick;
        let _interval = 1000;
        if (timerOptions.timerStatus & TimerStatusEnum.NotAlone) {
            _trigger = params.emitOnTimerExpire;
            _interval = 10000;
        }

        const intervalId = setInterval(_trigger, _interval);

        return () => clearInterval(intervalId);

    }, [timerOptions.timerStatus]);

    useEffect(() => {
        console.log("seconds updated", timerOptions);
        if (accountService.isAdmin) {

            tickLogService.log({
                userName: accountService.currentUserValue.email,
                status: timerOptions.timerStatus.toString() + " | seconds",
                lastUpdate: timerOptions.lastUpdate,
                seconds: timerOptions.seconds,
                created: new Date()
            });
        }

        if (timerOptions.seconds <= 0 && (timerOptions.timerStatus & TimerStatusEnum.IsRunning)) {
            params.emitOnTimerExpire();
        }

    }, [timerOptions.seconds]);

    function tick() {

        if (accountService.isAdmin) {
            tickLogService.log({
                userName: accountService.currentUserValue.email,
                status: timerOptions.timerStatus.toString() + " | tick",
                lastUpdate: timerOptions.lastUpdate,
                seconds: timerOptions.seconds,
                created: new Date()
            });
        }

        if (timerOptions.seconds > 0)
            setTimerOptions(s => {

                let _time = getSecondsRemaining(s.lastUpdate, s.seconds);

                // let _newUpdate = new Date().getTime();

                // let secondsUpdate = (s.lastUpdate === 0) ? 1 : Math.floor((_newUpdate - s.lastUpdate) / 1000);
                // secondsUpdate = secondsUpdate <= 0 ? 1 : secondsUpdate;

                // let _seconds = s.seconds - secondsUpdate;
                // _seconds = _seconds < 0 ? 0 : _seconds;
                // console.log(_time);
                return {
                    ...s,
                    seconds: _time.seconds,
                    lastUpdate: _time.lastUpdate
                }
            });
    }

    return (<>{formatedTimeStringFromSeconds(timerOptions.seconds)}</>);
}
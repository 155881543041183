

import { API_URL } from '../_common/const';
import { getHeader } from '../_helpers';
import { handleResponse } from '../_helpers/handle-response';

const API_NAME = "dropdown";

export const dropdownService = {
    getClientsForUser,
    getBuildingsForClient,
    getZonesForBuilding
};

function getClientsForUser() {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/ClientsForUser`, requestOptions).then(handleResponse);
}

function getBuildingsForClient(clientId?: number) {
    if (clientId === null || clientId === undefined)
        clientId = 0;
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/Buildings/?clientId=${clientId}`, requestOptions).then(handleResponse);
}

function getZonesForBuilding(buildingId?: number) {
    if (buildingId === null || buildingId === undefined)
        buildingId = 0;

    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/Zones/?buildingId=${buildingId}`, requestOptions).then(handleResponse);
}


import { useEffect, useState } from "react";
import { toastr } from "../../../_components/toastr/toastr";
import { apiConnectionService } from "../../../_services/apiConnection.service";
import { userLocationService } from "../../../_services/userLocation.service";
import { Alert } from "@material-ui/lab";

export function APIConnectionCheckComponent() {
    const [isConnectionOK, setIsConnectionOK] = useState(true);
    const [time, setTime] = useState(0);

    useEffect(() => {
        let timer = setInterval(() => { setTime(_time => { return ++_time }) }, 10000);
        return () => {
            console.log('clearing API connection check timer');
            clearInterval(timer)
        };
    }, []);

    useEffect(() => {
        if (time > 0) {
            checkAPIConnection();
        }
    }, [time]);

    function checkAPIConnection() {
        apiConnectionService.checkAPIConnection()
            .then(data => {
                setIsConnectionOK(true);
            },
                (e) => {
                    setIsConnectionOK(false);
                });
    }

    return (<>
        {!isConnectionOK &&
            <Alert variant="filled" severity="error">Connection to server has been lost!</Alert>
        }
    </>);
}
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import { IUserZoneManage } from '../../_common/models/userZone/userZoneManage.model';
import { accountService } from '../../_services';
import { userLoginService } from '../../_services/userLogin.service';
import { userZoneService } from '../../_services/userZone.service';
import { PrimaryButton } from '../buttons/primary.button';
import { toastr } from '../toastr/toastr';
import { AppBar, Toolbar, Typography, Box, IconButton, Icon, Dialog, Grid, DialogActions } from '@material-ui/core';
import React from 'react';
const logoImg = require('../../_assets/images/logo.png');

const drawerWidth = 240;

const useStyles = makeStyles(theme =>
    createStyles({
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,

        },
        appBar: {
            flexGrow: 1,
            // [theme.breakpoints.down('xs')]: {
            //     background: `linear-gradient(135deg, white 210px, ${theme.palette.primary.main} 0%)`
            // },
            // [theme.breakpoints.down('sm')]: {
            //     background: `linear-gradient(135deg, white 220px, ${theme.palette.primary.main} 0%)`
            // }
        },
        appLogo: {
            height: "92px",
            marginTop: "0px",
            display: 'block',
            marginLeft: '0px',
            position: "absolute",
            top: "1px"
        },
        topRightButton: {
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'white !important',
            "& > button > span": {
                color: "white !important"
            }
        },
        logoutButtonSize: {
            fontSize: '36px'
        },
        dateText: {
            fontSize: '20px',
            textAlign: 'center',
            fontWeight: 'bold'
        },
        finshWorkText: {
            fontSize: '30px',
            textAlign: 'center',
            fontWeight: 'bold'
        },
        finshWorkTitle: {
            backgroundColor: '#102843',
            color: '#FFF',

        },
        finishWorkButtons: {
            height: '60px',
            fontSize: '28px'
        }

    })
);

export const AppToolbar = (params: { toggleSidebar: () => void, isOpen: boolean }) => {

    const classes = useStyles();

    const [isOpen, setIsOpen] = useState(params.isOpen);
    const [isFinishWork, setIsFinishWork] = useState(false);
    const [isLoading, setloading] = useState(false);
    const [userZoneData, setUserZoneData] = useState<IUserZoneManage>(getZoneResetData);

    const nowDateStr = new Date().toLocaleString();

    useEffect(() => {
        setIsOpen(params.isOpen);
    }, [params.isOpen]);

    function getZoneResetData(): IUserZoneManage {
        return {
            zoneId: 0,
            buildingId: 0,
            clientId: 0,
            userId: '',
            userName: '',
            userPhoneNumber: '',
            zoneName: '',
            zoneAddress: '',
            startTimeInZone: null,
            endTimeInZone: null,
            isWorkingAlone: true,
            clientGracePeriodSeconds: 0,
            responseSecondsForZone: 0,
            isEscalationActive: false,
            isZoneMonitored: false,
            isBuildingOnTest: false,
            isTimeExtendable: true
        };
    }

    function finishWorkClick() {
        setIsFinishWork(true);

        setloading(true);

        return userZoneService
            .GetCurrentLoggedInUserZoneRecord()
            .then(
                (json: IUserZoneManage) => {
                    setUserZoneData(json);
                }, (e: any) => { toastr.error("Error signing out user"); }
            )
            .finally(() => setloading(false))
    }

    function finishWork() {
        setloading(true);

        return userLoginService
            .setUserFinishTime()
            .then(
                (json: any) => { }, (e: any) => { toastr.error("Error setting user finish time"); }
            )
            .finally(() => {
                setloading(false);
                accountService.logout();
            })
    }

    return (
        <>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <Typography variant="h6" className={classes.title}>

                        <img className={classes.appLogo} src={logoImg}></img>

                    </Typography>

                    <Box className={classes.topRightButton}>
                        <IconButton onClick={finishWorkClick}>
                            <Icon className={classes.logoutButtonSize}>exit_to_app</Icon>
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar >

            {isFinishWork && !isLoading &&
                <>
                    <Dialog onClose={() => setIsFinishWork(false)} open={true} fullWidth>

                        {/* 
                    <DialogTitle className={classes.finshWorkTitle}>

                        <h2>
                            Finish Work
                        </h2>
                        <IconButton
                            onClick={() => setIsFinishWork(false)}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: '#FFF',
                            }}
                        >
                            <Icon>close</Icon>
                        </IconButton>

                    </DialogTitle> */}
                        <Grid container spacing={0} direction="column" alignItems="center">
                            {/* <Grid item>
                                {userZoneData.zoneAddress}
                            </Grid>
                            <Grid item>
                                {userZoneData.zoneName}
                            </Grid> */}

                            <Grid item className={classes.finshWorkText}>
                                <h3>I am finished work</h3><h5>and leaving the premises</h5>
                            </Grid>
                            {userZoneData.isEscalationActive &&
                                <Grid item className={classes.finshWorkText}>
                                    <h3>The current active escalation alert will be closed</h3>

                                </Grid>
                            }
                            <Grid item className={classes.dateText}>
                                <h4>
                                    {nowDateStr}
                                </h4>
                            </Grid>
                        </Grid>
                        <DialogActions>
                            <PrimaryButton variant="success" onClick={finishWork}>Confirm</PrimaryButton>
                            <PrimaryButton variant="danger" onClick={() => setIsFinishWork(false)}>Cancel</PrimaryButton>
                        </DialogActions>
                    </Dialog>
                </>
            }
        </>
    );
};
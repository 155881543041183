import { API_URL } from '../_common/const';
import { getHeader } from '../_helpers';
import { handleResponse } from '../_helpers/handle-response';

const API_NAME = "userzone";

export const userZoneService = {
    createUserZoneRecord: CreateUserZoneRecord,
    GetCurrentLoggedInUserZoneRecord,
    confirmUserOKInZone: ConfirmUserOKInZone,
    SetWorkerEndTimeInZone,
    FinishWork,
    GetResponseMinutesForZone
};

function CreateUserZoneRecord(zoneId: number) {
    const requestOptions = { method: 'POST', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/CreateUserZoneRecord?zoneId=${zoneId}`, requestOptions).then(handleResponse);
}

function GetCurrentLoggedInUserZoneRecord() {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/GetCurrentLoggedInUserZoneRecord`, requestOptions).then(handleResponse);
}

function GetResponseMinutesForZone(zoneId: number) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/GetResponseMinutesForZone/?zoneId=${zoneId}`, requestOptions).then(handleResponse);
}

function ConfirmUserOKInZone() {
    const requestOptions = { method: 'PUT', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/ConfirmUserOKInZone`, requestOptions).then(handleResponse);
}

function SetWorkerEndTimeInZone() {
    const requestOptions = { method: 'PUT', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/SetWorkerEndTimeInZone`, requestOptions).then(handleResponse);
}

function FinishWork() {
    const requestOptions = { method: 'POST', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/FinishWork`, requestOptions).then(handleResponse);
}


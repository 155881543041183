import { API_URL } from '../_common/const';
import { getHeader } from '../_helpers';
import { handleResponse } from '../_helpers/handle-response';

const API_NAME = "userConnectionLog";

export const userConnectionLogService = {
    createUserLog
};

function createUserLog() {
    const requestOptions = { method: 'POST', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/CreateUserLog`, requestOptions).then(handleResponse);
}

import { getHeader } from '../_helpers';
import { handleResponse } from '../_helpers/handle-response';
import { API_URL } from '../_common/const';

const API_NAME = "userLogin";

export const userLoginService = {
    createUserLoginRecord,
    setUserFinishTime
};
function createUserLoginRecord() {
    const requestOptions = { method: 'POST', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/CreateUserLoginRecord`, requestOptions).then(handleResponse);
}

function setUserFinishTime() {
    const requestOptions = { method: 'PUT', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/SetUserFinishTime`, requestOptions).then(handleResponse);
}
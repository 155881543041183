import { Grid, Paper, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useRef, useState } from 'react';
import { CONFIRMACTION, IAMOK, INEEDASSISTANCE } from '../../_common/const/app.const';
import { TimerStatusEnum } from '../../_common/enum';
import { ITimerOptions } from '../../_common/models';
import { IUserZoneManage } from '../../_common/models/userZone/userZoneManage.model';
import { PrimaryButton } from '../../_components/buttons/primary.button';
import { ConfirmOKDialog } from '../../_components/dialog/confirmOK.dialog';
import { toastr } from '../../_components/toastr/toastr';
import { handleErrorResponse } from '../../_helpers';
import { getSecondsSince } from '../../_helpers/time.helper';
import { escalationAlertService } from '../../_services/escalationAlert.service';
import { userZoneService } from '../../_services/userZone.service';
import { WorkerZoneSelect } from './components/workerZoneSelect-component';
import { WorkerZoneTimer } from './components/workerZoneTimer-component';

import mp3 from "../../_assets/mp3/alarm.mp3";
import { APIConnectionCheckComponent } from './components/apiConnectionCheck-component';
import { GeoLocationRecorderComponent } from './components/geoLocationRecorder-component';

import { Alert } from '@material-ui/lab';
import { accountService } from '../../_services';
import { UserLocationLogComponent } from './components/userLocationLog-component';

const useStyles = makeStyles<Theme, { timerStatus: TimerStatusEnum }>(theme => ({
    bottomButtonPosition: {

    },
    timerPaper: (params) => ({
        // backgroundColor: `${(params.timerStatus === TimerStatusEnum.Grace ? WARNING_COLOR[0] : params.timerStatus === TimerStatusEnum.Grace ? DANGER_COLOR[0] : "auto")} !important`,
        // color: `${(params.timerStatus & TimerStatusEnum.ShouldAlertPlay ? "white" : "auto")} !important`
        "&.transition-smaller": {

            height: "175px",
            transition: "height 0.3s ease",
            "&>*": {
                marginTop: "-50px",
                transform: "scale(.5)",
                transition: "transform 0.3s ease, margin 0.3s ease",

            }
        },
        "&.transition-larger": {
            // height: "300px",
            transition: "height 0.3s ease",
            "&>*": {
                marginTop: "0px",
                transform: "scale(1)",
                transition: "transform 0.3s ease, margin 0.3s ease",

            }
        },
    }),
    timerText: {
        fontFamily: 'Orbitron',
        fontSize: '85px',
        // width: '385px',
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
            fontSize: '72px',
        },
    },
    mainText: {
        fontFamily: 'Orbitron',
        textAlign: "center"
    },
    testModeAlert: {
        position: "fixed",
        top: "110",
        width: "initial"
    }
}));

export function WorkerHomeIndex() {
    const alarm = useRef(new Audio(mp3));

    const [timerOptions, setTimerOptions] = useState<ITimerOptions>({
        timerStatus: TimerStatusEnum.Paused,
        totalMainSecondsCountdown: 0,
        totalGraceSecondsCountdown: 0,
        initialSecondsCountdown: 0,
        resetCounter: 0
    });

    const [userZoneData, setUserZoneData] = useState<IUserZoneManage>({
        zoneId: 0,
        buildingId: 0,
        clientId: 0,
        userId: '',
        userName: '',
        userPhoneNumber: '',
        zoneName: '',
        zoneAddress: '',
        startTimeInZone: null,
        endTimeInZone: null,
        isWorkingAlone: true,
        clientGracePeriodSeconds: 0,
        responseSecondsForZone: 0,
        isEscalationActive: false,
        isZoneMonitored: true,
        isBuildingOnTest: false,
        isTimeExtendable: true
    });

    const [changeZone, setChangeZone] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [isZoneMonitored, setIsZoneMonitored] = useState(true);
    const [isTimeExtendable, setIsTimeExtendable] = useState(true);
    const [isBuildingOnTest, setIsBuildingOnTest] = useState(false);
    const [isAudioPlaying, setIsAudioPlaying] = useState<boolean | null>(null);

    const isMainPeriodActive = timerOptions.timerStatus === TimerStatusEnum.Main;
    const isGracePeriodActive = timerOptions.timerStatus === TimerStatusEnum.Grace;
    const isAlertActive = timerOptions.timerStatus === TimerStatusEnum.Alert;
    const isUserOKConfirmationRequired = userZoneData.zoneId > 0 && (isGracePeriodActive || isAlertActive);

    const classes = useStyles({ timerStatus: timerOptions.timerStatus });

    const [milliSeconds, setMilliSeconds] = useState(30000);

    useEffect(() => {
        fetchCurrentUserZoneRecord();
    }, []);

    useEffect(() => {
        if ((TimerStatusEnum.ShouldAlertPlay & timerOptions.timerStatus) && !isAudioPlaying) {
            setIsAudioPlaying(true);
        }
        else if (!(TimerStatusEnum.ShouldAlertPlay & timerOptions.timerStatus) && isAudioPlaying === true) {
            setIsAudioPlaying(false);
        }

    }, [timerOptions.timerStatus]);

    useEffect(() => {
        if (isAudioPlaying === true) {

            alarm.current.loop = true;
            alarm.current.play();

            if ("vibrate" in navigator) {
                // vibration API supported
                navigator.vibrate([2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000]);
            }
        }
        else if (isAudioPlaying === false) {
            alarm.current.pause();

            if ("vibrate" in navigator) {
                // vibration API supported
                navigator.vibrate(0);
            }
        }

    }, [isAudioPlaying]);

    function fetchCurrentUserZoneRecord() {
        userZoneService
            .GetCurrentLoggedInUserZoneRecord()
            .then(
                (json: IUserZoneManage) => {
                    const sec = json.responseSecondsForZone;
                    const secondsRemaining = sec - getSecondsSince(json.startTimeInZone);

                    setIsZoneMonitored(json.isZoneMonitored);
                    setIsBuildingOnTest(json.isBuildingOnTest);
                    setIsTimeExtendable(json.isTimeExtendable);

                    let _timerStatus =
                        json.startTimeInZone === null || json.isBuildingOnTest || json.isZoneMonitored === false ? TimerStatusEnum.Paused :
                            !json.isWorkingAlone ? TimerStatusEnum.NotAlone :
                                secondsRemaining <= 0 || json.isEscalationActive ? TimerStatusEnum.Alert :
                                    secondsRemaining <= json.clientGracePeriodSeconds ? TimerStatusEnum.Grace : TimerStatusEnum.Main;

                    setTimerOptions(_options => {
                        return {
                            timerStatus: _timerStatus,
                            totalMainSecondsCountdown: sec,
                            totalGraceSecondsCountdown: json.clientGracePeriodSeconds,
                            initialSecondsCountdown: _timerStatus == TimerStatusEnum.Alert ? 0 : _timerStatus == TimerStatusEnum.NotAlone ? sec : secondsRemaining,
                            resetCounter: _options.resetCounter + 1
                        }
                    });
                    setUserZoneData(json);
                }, (e: any) => { toastr.error("Error getting user zone details"); }
            )
            .finally(() => { setLoading(false); });
    }

    function emitOnSaveZoneSelected() {
        setIsAudioPlaying(false);
        fetchCurrentUserZoneRecord();
    }

    function assistanceRequired() {
        setTimerOptions((options: ITimerOptions) => {
            return {
                ...options,
                initialSecondsCountdown: 0,
                timerStatus: TimerStatusEnum.Alert
            }
        });
        createPanicAlert();
    }

    function confirmOK() {

        if (!isMainPeriodActive && !confirmDialogOpen) {
            setConfirmDialogOpen(true);
            return;
        }

        return userZoneService
            .confirmUserOKInZone()
            .then(
                (json: any) => {
                    setConfirmDialogOpen(false);
                    fetchCurrentUserZoneRecord();
                    setIsAudioPlaying(false);
                }, handleErrorResponse
            );
    }

    function emitOnTimerExpire() {
        switch (timerOptions.timerStatus) {

            case (TimerStatusEnum.NotAlone):
                fetchCurrentUserZoneRecord();
                break;

            case (TimerStatusEnum.Main):
                setTimerOptions((options: ITimerOptions) => {
                    return {
                        ...options,
                        initialSecondsCountdown: options.totalGraceSecondsCountdown,
                        timerStatus: TimerStatusEnum.Grace
                    }
                });
                break;

            case (TimerStatusEnum.Grace):
                setTimerOptions((options: ITimerOptions) => {
                    return {
                        ...options,
                        initialSecondsCountdown: 0,
                        timerStatus: TimerStatusEnum.Alert
                    }
                });
                if (isZoneMonitored)
                    createTimeoutAlert();
                break;
        }
    }



    function createTimeoutAlert() {
        setIsAudioPlaying(true);
        return escalationAlertService.createTimeoutEscalationAlert()
            .then(null, handleErrorResponse);
    }

    function createPanicAlert() {
        setIsAudioPlaying(true);
        return escalationAlertService.createPanicEscalationAlert()
            .then(null, handleErrorResponse);
    }

    return (
        <>
            {!isLoading &&
                <>
                    <APIConnectionCheckComponent />


                    {isBuildingOnTest && <>
                        <Grid item xs={12}><Alert severity="error">Building is currently on test mode</Alert></Grid>
                    </>}

                    <GeoLocationRecorderComponent />
                    <UserLocationLogComponent />

                    <WorkerZoneSelect
                        userZoneSelect={{
                            clientId: userZoneData.clientId,
                            buildingId: userZoneData.buildingId,
                            zoneId: userZoneData.zoneId,
                            timerStatus: timerOptions.timerStatus
                        }}
                        emitOnZoneChangeToggle={(_changeZone) => setChangeZone(_changeZone)}
                        emitOnSaveZoneSelected={emitOnSaveZoneSelected}
                    />
                    {(userZoneData.zoneId !== 0) &&
                        <Paper className={`${classes.timerPaper} ${changeZone ? "transition-smaller" : "transition-larger"}`}>

                    {accountService.isAdmin && <button type="button" onClick={() => setIsAudioPlaying(_isAudioPlaying => !_isAudioPlaying)}>{isAudioPlaying ? "stop audio test" : "start audio test"}</button>}


                            <Grid container spacing={0} direction="column" alignItems="center" className={classes.mainText}>
                                <Grid item xs={12}>
                                    {isZoneMonitored && isTimeExtendable && <h2>Time Until Confirmation Required</h2>}
                                    {!isZoneMonitored && <h2>Zone is not Monitored</h2>}
                                    {!isTimeExtendable && <h2>Timer Extension not Allowed</h2>}
                                </Grid>
                                <Grid item xs={12} className={classes.timerText}>
                                    <div >
                                        <WorkerZoneTimer
                                            timerOptions={timerOptions}
                                            emitOnTimerExpire={emitOnTimerExpire} />
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <h2>
                                        {userZoneData.isWorkingAlone && 'You Are Working Alone'}
                                        {!userZoneData.isWorkingAlone && 'Working With Other Staff'}
                                    </h2>
                                </Grid>
                            </Grid>
                        </Paper>
                    }

                    {(userZoneData.zoneId !== 0) &&
                        <Paper>
                            <Grid container className={classes.bottomButtonPosition}>
                                <Grid item xs={isGracePeriodActive ? 4 : isUserOKConfirmationRequired ? 12 : 4}>
                                    <PrimaryButton
                                        disabled={(userZoneData.zoneId === 0 || !isTimeExtendable)}
                                        isDense={changeZone}
                                        onClick={confirmOK}
                                        variant={isGracePeriodActive ? "blinkConfirmOKWarning" : isUserOKConfirmationRequired ? "blinkConfirmOKDanger" : "confirmOK"}>
                                        I am OK
                                    </PrimaryButton>
                                </Grid>

                                {(!isUserOKConfirmationRequired || isGracePeriodActive) &&
                                    <Grid item xs={8}>
                                        <PrimaryButton
                                            isDense={changeZone}
                                            onClick={assistanceRequired}
                                            disabled={userZoneData.zoneId === 0 ? true : isGracePeriodActive ? false : isUserOKConfirmationRequired ? true : false}
                                            variant="needAssistance">
                                            {INEEDASSISTANCE}
                                        </PrimaryButton>
                                    </Grid>}
                            </Grid>
                        </Paper>
                    }

                    <ConfirmOKDialog
                        dialogTitle={CONFIRMACTION}
                        showOKRequired={true}
                        okText={IAMOK}
                        isOpen={confirmDialogOpen}
                        showAssistanceRequired={!isUserOKConfirmationRequired}
                        assistanceText={INEEDASSISTANCE}
                        emitOnConfirmOK={confirmOK}
                        emitOnAssistanceRequired={assistanceRequired}
                        emitOnCancel={() => setConfirmDialogOpen(false)}
                    />
                </>
            }
        </>
    );
};

import { API_URL } from '../_common/const';
import { getBody, getHeader } from '../_helpers';
import { handleResponse } from '../_helpers/handle-response';

const API_NAME = "tickLog";

export const tickLogService = {
    log: log
};

function log(model: any) {
    const requestOptions = { method: 'POST', headers: getHeader(), body: getBody(model) };
    return fetch(`${API_URL}/${API_NAME}/`, requestOptions).then(handleResponse);
}


import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { Switch } from 'react-router-dom';
import { THEME } from '../_common/const';
import { RoleEnum } from '../_common/enum';
import { AuthRoute } from '../_components/routing/auth.routing';
import { AppToolbar } from '../_components/toolbar/app.toolbar';
import { historyHelper } from '../_helpers';
import { accountService } from '../_services';
import { WorkerHomeIndex } from './home/workerHome-index';
import { userLoginService } from '../_services/userLogin.service';
import { toastr } from '../_components/toastr/toastr';
import { CssBaseline, Container } from '@material-ui/core';


const drawerWidth = 240;
const useStyles = makeStyles(theme => ({

    appScreen: {
        display: 'flex',
        height: "100%"
    },
    main: {
        marginTop: "40px"
    },
    root: {

    },
    appLogo: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    mainBackground: {},
    stickToBottom: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
        borderTop: '1px solid #eee'
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        paddingTop: theme.spacing(5),
        width: 'calc(100% - 650px)',
        maxWidth: "calc(100%)",
        [theme.breakpoints.up('md')]: {
        },
        [theme.breakpoints.down('md')]: {
            paddingTop: theme.spacing(6),
        },
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0
    }
}));


export function AppScreen() {

    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (e: any, nav: any) => {
        setAnchorEl(null);
        if (nav && nav.url)
            goTo(nav.url);
    };

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    }


    function goTo(link: string) {
        historyHelper.push('/' + link);
    }

    if (accountService.isInRole(RoleEnum.Employee)) {
        console.log('employee');
    }

    return (
        <div className={classes.appScreen} id="app-screen">
            <ThemeProvider theme={THEME}>
                <CssBaseline />

                <AppToolbar toggleSidebar={toggleSidebar} isOpen={isOpen}></AppToolbar>

                <main className={clsx(classes.content, {
                    [classes.contentShift]: isOpen,
                })}>
                    <div className={classes.toolbar} />
                    <Container component="main">
                        <Switch>
                            <AuthRoute component={WorkerHomeIndex} />
                        </Switch>
                    </Container>
                </main>
            </ThemeProvider>
        </div >
    );
}
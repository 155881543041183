import { useEffect, useState } from "react";
import { getSecondsRemaining } from "../../../_helpers/time.helper";
import { userConnectionLogService } from "../../../_services";


const _seconds = 60;
export function UserLocationLogComponent() {
    const [isLoading, setLoading] = useState(false);
    const [timerOptions, setTimerOptions] = useState({
        seconds: _seconds,
        lastUpdate: 0
    });

    useEffect(() => {
        let timer = setInterval(tick, 30000);
        return () => { clearInterval(timer) };
    }, []);

    function tick() {
        setTimerOptions(s => {
            if (s.seconds <= 0) return s;

            let _time = getSecondsRemaining(s.lastUpdate, s.seconds);

            return {
                ...s,
                seconds: _time.seconds,
                lastUpdate: _time.lastUpdate
            }
        });
    }

    useEffect(() => {
        if (timerOptions.seconds <= 0) {
            recordUserLocationLog();
        }
    }, [timerOptions.seconds]);

    function recordUserLocationLog() {
        userConnectionLogService.createUserLog()
            .then(data => { console.log('location recorded') },
                (e) => {
                    console.log("Can't log");
                })
            .finally(() => {
                setTimerOptions(s => {
                    return {
                        ...s,
                        seconds: _seconds,
                    }
                });
            });
    }

    return (<></>);
}
import { Dialog, DialogTitle, IconButton, Icon, DialogActions } from "@material-ui/core";
import React from "react";
import { PrimaryButton } from "../buttons/primary.button";


export function ConfirmOKDialog(params: {
    dialogTitle: string,
    showOKRequired: boolean,
    okText: string,
    showAssistanceRequired: boolean,
    isOpen: boolean;
    assistanceText: string,
    emitOnConfirmOK: () => void,
    emitOnAssistanceRequired: () => void,
    emitOnCancel: () => void
}) {
    return (
        <Dialog open={params.isOpen} fullWidth>
            <DialogTitle>
                {params.dialogTitle}
                {params.emitOnCancel ? (
                    <IconButton
                        onClick={params.emitOnCancel}
                        style={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            // "color": (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Icon>close</Icon>
                    </IconButton>) : null}
            </DialogTitle>
            <DialogActions>
                {params.showOKRequired && <PrimaryButton variant="confirmOK" onClick={params.emitOnConfirmOK}> {params.okText} </PrimaryButton>}
                {params.showAssistanceRequired && <PrimaryButton variant="needAssistance" onClick={params.emitOnAssistanceRequired}> {params.assistanceText} </PrimaryButton>}
            </DialogActions>
        </Dialog>
    );
};